<template>
  <component :is="customerData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="customerData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.customers.clientNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-customer-list' }">
          {{ $t("message.customers.clientList") }}
        </b-link>
        {{ $t("message.customers.forOtherCustomers") }}
      </div>
    </b-alert>

    <template v-if="customerData">
      <div>
        <b-card>
          <h2 class="mb-4">{{ $t("message.customers.editCustomer") }}</h2>
          <b-row>
            <b-col md="8">
              <b-form-group label-for="nameCustomers">
                <template v-slot:label>
                  {{ $t("message.tableHeader.name") }}
                </template>
                <b-form-input
                  v-model="customerData.name"
                  class="mt-0"
                  label="name"
                  label-for="nameCustomers"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="cnpjCustomers">
                <template v-slot:label>
                  {{ $t("message.register.cnpj") }}
                </template>
                <b-form-input
                  v-model="customerData.identificationNumber"
                  class="mt-0"
                  label="cnpj"
                  label-for="cnpjCostumers"
                />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label-for="phoneNumbersCustomers">
                <template v-slot:label>
                  {{ $t("message.register.phone") }}
                </template>
                <b-form-input
                  v-model="phones"
                  class="mt-0"
                  label="phoneNumbers"
                  label-for="phoneNumbersCostumers"
                /><small style="color: lightgrey"
                  >Separe com , se houver mais de um telefone</small
                >
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label-for="emailsCustomers">
                <template v-slot:label>
                  {{ $t("message.register.email") }}
                </template>
                <b-form-input
                  v-model="emails"
                  class="mt-0"
                  label="emails"
                  label-for="emailsCostumers"
                /><small style="color: lightgrey"
                  >Separe com , se houver mais de um email</small
                >
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="contactsCustomers">
                <template v-slot:label>
                  {{ $t("message.register.contact") }}
                </template>
                <b-form-input
                  v-model="contacts"
                  class="mt-0"
                  label="emails"
                  label-for="emailsCostumers"
                /><small style="color: lightgrey"
                  >Separe com , se houver mais de um telefone</small
                >
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="5">
              <b-form-group label-for="addresCustomers">
                <template v-slot:label>
                  {{ $t("message.register.address") }}
                </template>
                <b-form-input
                  v-model="customerData.address"
                  class="mt-0"
                  label="addresCustomers"
                  label-for="addresCostumers"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="cepCustomers">
                <template v-slot:label>
                  {{ $t("message.register.zipCode") }}
                </template>
                <b-form-input
                  v-model="customerData.zipCode"
                  class="mt-0"
                  label="cpCustomers"
                  label-for="cpCostumers"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="districtCustomers">
                <template v-slot:label>
                  {{ $t("message.register.district") }}
                </template>
                <b-form-input
                  v-model="customerData.district"
                  class="mt-0"
                  label="districtCustomers"
                  label-for="districtCustomers"
                />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label-for="cityCustomers">
                <template v-slot:label>
                  {{ $t("message.register.city") }}
                </template>
                <b-form-input
                  v-model="customerData.city"
                  class="mt-0"
                  label="cityNumbers"
                  label-for="cityCostumers"
                />
              </b-form-group>
            </b-col>

            <b-col md="1">
              <b-form-group label-for="ufCustomers">
                <template v-slot:label>
                  {{ $t("message.register.uf") }}
                </template>
                <b-form-input
                  v-model="customerData.uf"
                  class="mt-0"
                  label="ufCustomers"
                  label-for="ufCostumers"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
    <div class="text-right">
      <b-button
        variant="primary"
        @click="updateCustomer()"
        :to="{ name: 'apps-customer-list' }"
      >
        <!-- <b-button variant="primary" @click="updateEmployee()"> -->
        {{ $t("message.buttons.save") }}</b-button
      >
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    quillEditor,
  },
  data() {
    return {
      //select \\
      userOptions: [],
      teamOptions: [],
      //multilingual i18n \\
      locale: this.$i18n.locale,

      emails: "",
      phones: "",
      contacts: "",

      customerData: [],

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      userData: store.state.user.userData,
    };
  },

  async created() {
    await axios({
      method: "get",
      url: `${URL_API}customer/${router.currentRoute.params.id}`,
      headers: getHeader(this.userData),
    })
      .then((response) => {
        this.customerData = response.data;
        this.emails = response.data.emailAdresses.join(",");
        this.phones = response.data.phoneNumbers.join(",");
        this.contacts = response.data.contacts.join(",");
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.customerData = undefined;
        }
      });
  },

  methods: {
    updateCustomer() {
      this.customerData.emailAdresses = [];
      this.customerData.phoneNumbers = [];
      this.customerData.contacts = [];

      this.customerData.emailAdresses = this.emails.split(",");
      this.customerData.phoneNumbers = this.phones.split(",");
      this.customerData.contacts = this.contacts.split(",");

      axios({
        method: "put",
        url: `${URL_API}customer/${this.customerData.id}`,
        headers: getHeader(this.userData),
        data: this.customerData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },
  },
};
</script>
